<template>
    <div class="">
        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="caz-blocks-sarcho-title">
                            <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                                {{ $t("message.deletedDeals") }}
                            </div>
                            <div class="block-sarche">
                                <div class="header__search">
                                    <crm-input
                                        :size="'small'"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        :className="'w100'"
                                        v-model="filterForm.search"
                                        :icon="'el-icon-search'"
                                    ></crm-input>
                                </div>
                            </div>
                        </div>
                    </el-col>

                   <el-col :span="12" class="flex-style text-right">
                        <!-- <el-dropdown class="setting-cheek ml-2">
                        <el-button
                            class="padding_none"
                            size="small"
                            icon="el-icon-open"
                        ></el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                            v-for="(column, index) in columns"
                            :key="'drop' + index"
                            >
                            <el-checkbox
                                :checked="column.show"
                                @change="check(column.column, $event)"
                                >{{ column.title }}
                            </el-checkbox>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                        </el-dropdown> -->
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->

            <div class="card-table-header table-crm-smart custom__scroll">
                <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered layout__initial">
                    <thead>
                        <tr>
                            <th class="w50p" v-if="columns.id.show">
                                {{ columns.id.title }}
                            </th>

                            <th v-if="columns.user.show">
                                {{ $t('message.responsible') }}
                            </th>

                            <th v-if="columns.delivery_deal_id.show">
                                {{  columns.delivery_deal_id.title }}
                            </th>

                            <th v-if="columns.tracking_code.show">
                                    {{ columns.tracking_code.title }}
                            </th>

                            <th v-if="columns.payer.show">
                                {{ columns.payer.title }}
                            </th>

                            <th v-if="columns.sender.show">
                                {{ columns.sender.title }}
                            </th>

                            <th v-if="columns.sender_address.show">
                                {{ columns.sender_address.title }}
                            </th>

                            <th v-if="columns.reciever.show">
                                {{ columns.reciever.title }}
                            </th>

                            <th v-if="columns.reciever_address.show">
                                {{ columns.reciever_address.title }}
                            </th>

                            <th v-if="columns.partner.show">
                                {{ columns.partner.title }}
                            </th>

                            <th v-if="columns.all_weight.show">
                                {{ columns.all_weight.title }}
                            </th>

                            <th v-if="columns.total.show">
                                {{ columns.total.title }}
                            </th>

                            <th v-if="columns.paid_money.show">
                                {{ columns.paid_money.title }}
                            </th>

                            <th v-if="columns.debt.show">
                                {{ columns.debt.title }}
                            </th>

                            <th v-if="columns.from_filial.show">
                                {{ columns.from_filial.title }}
                            </th>

                            <th v-if="columns.to_filial.show">
                                {{ columns.to_filial.title }}
                            </th>

                            <th v-if="columns.direction_tariff.show">
                                {{ columns.direction_tariff.title }}
                            </th>

                            <!-- <th v-if="columns.products.show">
                                {{ columns.products.title }}
                            </th> -->

                            <th v-if="columns.begin_date.show">
                                {{ columns.begin_date.title }}
                            </th>

                            <th v-if="columns.end_date.show">
                                {{ columns.end_date.title }}
                            </th>

                            <th v-if="columns.comment.show">
                                {{ columns.comment.title }}
                            </th>

                            <th v-if="columns.created_at.show">
                                {{ columns.created_at.title }}
                            </th>

                            <th v-if="columns.updated_at.show">
                                {{ columns.updated_at.title }}
                            </th>

                            <th>
                                {{ columns.deleted_at.title }}
                            </th>

                            <!-- <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th> -->
                        </tr>

                        <tr class="filter_sorche">
                            <th v-if="columns.id.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.id"
                                    :placeholder="columns.id.title"
                                    class="id_input"
                                ></el-input>
                            </th>

                            <th v-if="columns.delivery_deal_id.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.delivery_deal_id"
                                    :placeholder="columns.delivery_deal_id.title"
                                    class="id_input"
                                ></el-input>
                            </th>

                            <th v-if="columns.user.show">
                                <select-users
                                    :placeholder="$t('message.responsible')"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.user_id"
                                ></select-users>
                            </th>

                            <th v-if="columns.tracking_code.show">
                                <crm-input
                                    :placeholder="$t('message.tracking_code')"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.tracking_code"
                                ></crm-input>
                            </th>

                            <th v-if="columns.payer.show">
                                    <select-client
                                        :size="'medium'"
                                        :class="mode ? 'filter__day' : 'filter__night'"
                                        :placeholder="columns.payer.title"
                                        v-model="filterForm.payer_client_id"
                                    >
                                    </select-client>
                            </th>

                            <th v-if="columns.sender.show">
                                    <select-client
                                        :size="'medium'"
                                        :class="mode ? 'filter__day' : 'filter__night'"
                                        :placeholder="columns.sender.title"
                                        v-model="filterForm.client_id"
                                    >
                                    </select-client>
                            </th>

                            <th v-if="columns.sender_address.show">
                                <crm-input
                                    :placeholder="columns.sender_address.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :disable="true"
                                    v-model="filterForm.sender_address"
                                ></crm-input>
                            </th>

                            <th v-if="columns.reciever.show">
                                    <select-client
                                        :size="'medium'"
                                        :class="mode ? 'filter__day' : 'filter__night'"
                                        :placeholder="columns.reciever.title"
                                        v-model="filterForm.to_client_id"
                                    >
                                    </select-client>
                            </th>

                            <th v-if="columns.reciever_address.show">
                                <crm-input
                                    :placeholder="columns.reciever_address.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :disable="true"
                                    v-model="filterForm.reciever_address"
                                ></crm-input>
                            </th>

                            <th v-if="columns.partner.show">
                                    <select-client
                                        :size="'medium'"
                                        :class="mode ? 'filter__day' : 'filter__night'"
                                        :placeholder="columns.partner.title"
                                        :query="{clientType_id: 1}"
                                        v-model="filterForm.partner_client_id"
                                    >
                                    </select-client>
                            </th>

                            <th v-if="columns.all_weight.show"></th>
                            <th v-if="columns.total.show"></th>
                            <th v-if="columns.paid_money.show"></th>
                            <th v-if="columns.debt.show"></th>

                            <th v-if="columns.from_filial.show">
                                <select-from-filial
                                    :size="'medium'"
                                    :select_only="false"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.from_filial.title"
                                    v-model="filterForm.from_filial_id"
                                >
                                </select-from-filial>
                            </th>
                            <th v-if="columns.to_filial.show">
                                <select-to-filial
                                    :size="'medium'"
                                    :select_only="false"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.to_filial.title"
                                    v-model="filterForm.to_filial_id"
                                >
                                </select-to-filial>
                            </th>

                            <th v-if="columns.direction_tariff.show">
                                    <select-tariff
                                    :size="'large'"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="$t('message.directionTariff')"
                                    :id="filterForm.direction_tariff_id"
                                    :from_filial_id="filterForm.from_filial_id"
                                    :to_filial_id="filterForm.to_filial_id"
                                    :show_all="true"
                                    v-model="filterForm.direction_tariff_id"
                                    >
                                    </select-tariff>
                            </th>

                            <!-- <th v-if="columns.products.show">
                                <crm-input
                                    disabled
                                    :placeholder="columns.products.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.products"
                                ></crm-input>
                            </th> -->

                            <th v-if="columns.begin_date.show">
                                <crm-date-picker
                                    :placeholder="columns.begin_date.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.begin_date"
                                ></crm-date-picker>
                            </th>

                            <th v-if="columns.end_date.show">
                                <crm-date-picker
                                    :placeholder="columns.end_date.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.end_date"
                                ></crm-date-picker>
                            </th>
                            
                            <th v-if="columns.comment.show">
                                
                            </th>
                            
                            <th v-if="columns.created_at.show">
                                <crm-date-picker
                                    :placeholder="columns.created_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.created_at"
                                ></crm-date-picker>
                            </th>

                            <th v-if="columns.updated_at.show">
                                <crm-date-picker
                                    :placeholder="columns.updated_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.updated_at"
                                ></crm-date-picker>
                            </th>

                            <th>
                                <crm-date-picker
                                    :placeholder="columns.deleted_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.deleted_at"
                                ></crm-date-picker>
                            </th>

                            <!-- <th
                                class="settinW"
                                v-if="columns.settings.show"
                            ></th> -->
                        </tr>
                    </thead>
                    <transition-group name="flip-list" tag="tbody">
                        <tr
                            v-for="deal in list"
                            :key="deal.id"
                            class="cursor-pointer"
                        >
                            <td v-if="columns.id.show">
                                <div style="font-weight: 700;">
                                    <i v-if="deal.cargo_type === 'parcel'" class="el-icon-message" style="color: #0017ff;"></i> 
                                    <i v-else class="el-icon-box" style="color: #7f6918;"></i> 
                                    {{ deal.id }}
                                </div>
                            </td>

                            <td v-if="columns.user.show">
                                {{ deal.user ? deal.user.name : '' }}
                            </td>

                            <td v-if="columns.delivery_deal_id.show">
                                {{ deal.delivery_deal_id }}
                            </td>
                            
                            <td v-if="columns.tracking_code.show">
                                {{ deal.tracking_code }}
                            </td>

                            <td v-if="columns.payer.show">
                                <span v-if="deal.payer">
                                    {{ deal.payer.custom_id ? ('ID: ' + deal.payer.custom_id) : '' }} <br>
                                    {{ deal.payer.name}} <br>
                                    {{ deal.payer.phone}}
                                </span>
                            </td>

                            <td v-if="columns.sender.show">
                                {{ deal.client ? (deal.client.custom_id ? ('ID: ' + deal.client.custom_id) : '') : '' }} <br>
                                {{ deal.client ? deal.client.name : '' }} <br>
                                {{ deal.client ? deal.phone : '' }}
                            </td>

                            <td v-if="columns.sender_address.show">
                                {{ (deal.deal_type_id === 1) ? '' : deal.client_deal_address }}
                            </td>

                            <td v-if="columns.reciever.show">
                                    <span v-if="deal.reciever">
                                        {{ deal.reciever ? (deal.reciever.custom_id ? ('ID: ' + deal.reciever.custom_id) : '') : '' }} <br>
                                        {{ deal.reciever ? deal.reciever.name : '' }}<br>
                                        {{ deal.reciever ? deal.to_client_phone : '' }}
                                    </span>
                            </td>

                            <td v-if="columns.reciever_address.show">
                                {{ (deal.deal_type_id === 1) ? deal.client_deal_address : deal.to_client_deal_address }}
                            </td>

                            <td v-if="columns.partner.show">
                                    <span v-if="deal.partner">
                                        {{ deal.partner ? (deal.partner.custom_id ? ('ID: ' + deal.partner.custom_id) : '') : '' }} <br>
                                        {{ deal.partner ? deal.partner.name : '' }}<br>
                                        {{ deal.partner ? deal.partner_phone : '' }}
                                    </span>
                            </td>

                            <td v-if="columns.all_weight.show">
                                {{ deal.all_weight | formatNumber(1)}} kg
                                    / 
                                {{ (deal.all_weight * $kg_to_pound) | formatNumber(1)}} lbs
                            </td>

                            <td v-if="columns.total.show">
                                {{ calculateDealTotal(deal) | formatMoney(1) }}
                            </td>

                            <td v-if="columns.paid_money.show">
                                {{ deal.paid_money | formatMoney(1) }}
                            </td>

                            <td v-if="columns.debt.show">
                                {{ (calculateDealTotal(deal) - deal.paid_money) | formatMoney(1) }}
                            </td>

                            <td v-if="columns.from_filial.show">
                                {{deal.from_filial ? deal.from_filial.name : '' }}
                            </td>

                            <td v-if="columns.to_filial.show">
                                {{deal.to_filial ? deal.to_filial.name : '' }}
                            </td>

                            <td v-if="columns.direction_tariff.show">
                                {{deal.directionTariff ? deal.directionTariff.name : '' }}
                            </td>

                            <!-- <td v-if="columns.products.show">
                                <span @click="showItems(deal)" style="cursor: pointer;">
                                    {{ $t('message.show') }} <span class="el-icon-arrow-right"></span>
                                </span>
                            </td> -->

                            <td v-if="columns.begin_date.show">
                                {{ deal.begin_date }}
                            </td>

                            <td v-if="columns.end_date.show">
                                {{ deal.end_date }}
                            </td>

                            <td v-if="columns.comment.show">
                                {{ deal.comment }}
                            </td>

                            <td v-if="columns.created_at.show">
                                {{ deal.created_at }}
                            </td>

                            <td v-if="columns.updated_at.show">
                                {{ deal.updated_at }}
                            </td>

                            <td>
                                {{ deal.deleted_at }}
                            </td>

                            <!-- <td
                                v-if="columns.settings.show"
                                class="settings-td"
                            >
                                <div class="flight__edit">
                                    <a class="ml-1" @click="edit(deal)" v-if="permissions.some(per => per.slug == 'deals.edit')">
                                        <i class="fa fa-eye"></i>
                                    </a>
                                    <a class="ml-1" @click="edit(deal, true)" v-if="permissions.some(per => per.slug == 'deals.edit')">
                                        <i class="fa-regular fa-pen-to-square"></i>
                                    </a>
                                </div>
                            </td> -->
                        </tr>
                    </transition-group>

                </table>
                <div class="my___pagination">
                    <crm-pagination
                        @c-change="updatePagination"
                        :class="mode ? 'pagination__day' : 'pagination__night'"
                        :pagination="pagination"
                    ></crm-pagination>
                    <!-- <Pagination /> -->
                </div>
            </div>

            <el-dialog :title="$t('message.deal')" :visible.sync="visibleShow" width="80%" @closed="empty()">
                <crm-show :deal_id="selectedItem" :open="visibleShow"></crm-show>
            </el-dialog>

            <el-dialog class="dialog__modal" :title="$t('message.products')" :visible.sync="dialogItemsVisible" width="60%" @closed="empty()" >
                <div v-loading="loadingProducts">
                  <el-table :data="dealProducts" show-summary :summary-method="getSummaries" border>
                    <el-table-column
                        type="index"
                        width="50">
                    </el-table-column>
                    <el-table-column prop="name" :label="$t('message.name')">
                        <template slot-scope="scope">
                          {{ scope.row.product ? scope.row.product.name : '' }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="quantity" :label="$t('message.quantity')"></el-table-column>
                    
                    <el-table-column prop="incoming_quantity" :label="$t('message.incoming_quantity')">
                        <template slot-scope="scope">
                          {{ scope.row.incoming_quantity }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="remainder" :label="$t('message.remainder')">
                        <template slot-scope="scope">
                          {{ scope.row.quantity - scope.row.incoming_quantity }}
                        </template>
                    </el-table-column>

                   <el-table-column prop="weight" :label="$t('message.weight_in_kg')"></el-table-column>
                   <el-table-column prop="weight_in_pounds" :label="$t('message.weight_in_pounds')"></el-table-column>
                    <el-table-column prop="price" :label="$t('message.price')">
                        <template slot-scope="scope">
                          {{ scope.row.price | formatNumber(1) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="currency" :label="$t('message.currency')">
                        <template slot-scope="scope">
                          {{ (scope.row.currency ? scope.row.currency.code : '')}}
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('message.total')">
                        <template slot-scope="scope">
                          {{ (parseFloat(scope.row.currency_rate) * parseFloat(scope.row.weight) * parseFloat(scope.row.price)).toFixed(2) }} 
                          {{ system_currency }}
                        </template>
                    </el-table-column>
                  </el-table>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import { mapGetters, mapActions } from "vuex";
import CrmShow from "./components/crm-show";
import selectClient from "@/components/inventory/select-client";
import selectTariff from "@/components/selects/select-direction-tariff";
import selectFromFilial from "@/components/selects/select-from-filial";
import selectToFilial from "@/components/selects/select-to-filial";
import selectReason from "@/components/inventory/select-reason";
import SelectUsers from '../../components/inventory/select-users';
import { formatMoney, formatNumber } from "@/filters/index";

export default {
    name: "deletedDeals",
    mixins: [list],
    components: {
        CrmShow,
        selectClient,
        selectTariff,
        selectFromFilial,
        selectToFilial,
        selectReason,
        SelectUsers
    },
    data() {
        return {
            visibleShow: false,
            dialogItemsVisible: false,
            loadingProducts: false,
        };
    },

    computed: {
        ...mapGetters({
            dealProducts: "dealProducts/dealProducts",
            list: "deals/deletedDeals",
            columns: "deals/columns",
            pagination: "deals/pagination",
            filter: "deals/filter",
            sort: "deals/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "deals/deletedDeals",
            setPagination: "deals/setPagination",
            updateSort: "deals/updateSort",
            updateFilter: "deals/updateFilter",
            updateColumn: "deals/updateColumn",
            updatePagination: "deals/updatePagination",
            show: "deals/show",
            empty: "deals/empty",
            delete: "deals/destroy",
            refreshData: "deals/refreshData",
            restoreDeal: "deals/restoreDeal",
            updateDealProducts: "dealProducts/show",
        }),
        check: function (column, event) {
            this.updateColumn({ key: column, value: event });
        },
        showItems(model) {
            this.dialogItemsVisible = true;
            this.loadingProducts = true;
            let placement = this.filterForm.placement ? this.filterForm.placement : '';
            this.updateDealProducts({id: model.id, placement: placement, show: false})
                .then((res) => {
                    this.loadingProducts = false;
                })
                .catch((err) => {
                    this.loadingProducts = false;
                });
        },
        restoringDeal(command){
            if(command.action === 'restore'){
                this.$confirm(
                    this.$t('message.Are you sure you want to activate the deal ?'),
                    this.$t('message.warning'),
                    {
                    confirmButtonText: this.$t('message.yes'),
                    cancelButtonText: this.$t('message.no'),
                    type: "warning",
                    }
                )
                .then(() => {
                    this.restoreDeal(command.id)
                        .then((res) =>{
                            this.$alert(res);
                            this.fetchData()
                        })
                        .catch((err) => {
                            this.$alert(err);
                        })
                })
                .catch(() => {
                    this.$message({
                        type: "warning",
                        message: this.$t('message.activating_deal_canceled'),
                    });
                });
            }else{
                this.visibleShow = true;
                this.selectedItem = command.id; 
            }
        },

        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            if(data && data.length > 0){

                columns.forEach((column, index) => {
                    if (index === 1) {
                        sums[index] = this.$t('message.total');
                        return;
                    }
                    if (column.label === this.$t('message.weight_in_kg')) {
                        const mapped_arr = data.map(item => item.weight);
                        let total = mapped_arr.reduce((prev, next) => parseFloat(prev) + (parseFloat(next) || 0));
                        sums[index] = formatNumber(total, 1) + ' ' + this.$t('message.kg');
                        return;
                    }
                    if (column.label === this.$t('message.weight_in_pounds')) {
                        const mapped_arr = data.map(item => item.weight_in_pounds);
                        let total = mapped_arr.reduce((prev, next) => parseFloat(prev) + (parseFloat(next) || 0));
                        sums[index] = formatNumber(total, 1) + ' ' + 'lbs';
                        return;
                    }
                    if (column.label === this.$t('message.quantity')) {
                        const mapped_arr = data.map(item => item.quantity);
                        let total = mapped_arr.reduce((prev, next) => parseFloat(prev) + (parseFloat(next) || 0));
                        sums[index] = formatNumber(total, 1);
                        return;
                    }
                    if (column.label === this.$t('message.incoming_quantity')) {
                        const mapped_arr = data.map(item => item.incoming_quantity);
                        let total = mapped_arr.reduce((prev, next) => parseFloat(prev) + (parseFloat(next) || 0));
                        sums[index] = formatNumber(total, 1);
                        return;
                    }

                    if (column.label === this.$t('message.total')) {
                        const mapped_arr = data.map(item => item.price * item.currency_rate * item.weight);
                        let total = mapped_arr.reduce((prev, next) => parseFloat(prev) + (parseFloat(next) || 0));
                        sums[index] = formatNumber(total, 1) + process.env.VUE_APP_CURRENCY;
                        return;
                    }
                    
                });
            }
            return sums;
        },
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('deals/EMPTY_DELETED_DEALS_LIST');
        next()
    },
};
</script>

